import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FixedWidth, Header } from "../../layout/Semantic";
import { H1 } from "../../copy";
import Content from "../../../content/about.json";
import ScrollVisibilityObserver from "../../utils/ScrollVisibilityObserver";

const CubeShadow = styled(motion.div)`
  position: absolute;
  clip-path: polygon(164px 140px, 100% 65%, 100% 100%, 0 100%, 164px 228px);
  height: 328px;
  left: calc(50% + 16px);
  width: 640px;
  bottom: 78px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.COLORS.abyss},
    ${({ theme }) => theme.COLORS.marine} 60%,
    ${({ theme }) => theme.COLORS.marine} 75%,
    ${({ theme }) => theme.COLORS.vermilion}
  );
`;

const Cube = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 384px;
  width: 384px;
  position: relative;
`;

const CubePanel = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 256px;
  height: 256px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
  position: absolute;
  transform: ${({ transform3d }) => transform3d || "unset"};
`;

export const CubeSticker = styled(motion.div)`
  background: ${({ theme, patternColor, patternDirection, bgColor }) => {
    return patternColor && patternDirection && bgColor
      ? `repeating-linear-gradient(${patternDirection}, ${theme.COLORS[patternColor]} 0, ${theme.COLORS[patternColor]} 1px, ${theme.COLORS[bgColor]} 1px , ${theme.COLORS[bgColor]} 7px)`
      : "unset";
  }};
  background-color: ${({ theme, bgColor }) =>
    theme.COLORS[bgColor] || theme.COLORS.abyss};
  width: 124px;
  height: 124px;
  border: 2px solid #fff;
  border-radius: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: ${({ transform3d }) => transform3d || "unset"};
`;

export const StickerLabel = styled(motion.span)`
  font-family: ${({ theme }) => theme.FONTS.family1};
  font-size: ${({ theme }) => theme.FONTS.sizes.large};
  color: ${({ color, theme }) =>
    color ? theme.COLORS[color] : theme.COLORS.abyss};
`;

const Hero = (props) => {
  return (
    <Header style={{ alignItems: "center", paddingBottom: 128 }}>
      <H1>{Content.title}</H1>

      <ScrollVisibilityObserver>
        {({ isInView }) => {
          return (
            <>
              <FixedWidth
                className="absolute"
                style={{ top: 0, bottom: 0, overflow: "hidden" }}
              >
                <CubeShadow
                  initial={{ opacity: 0 }}
                  variants={{
                    show: { opacity: 1, transition: { delay: 1.6 } },
                    hide: { opacity: 0 },
                  }}
                  animate={isInView ? "show" : "hide"}
                />
              </FixedWidth>
              <Cube
                variants={{
                  before: {
                    opacity: 0,
                  },
                  drop: {
                    opacity: 1,
                    transition: {
                      when: "beforeChildren",
                      staggerChildren: 0.2,
                    },
                  },
                }}
                animate={isInView ? "drop" : "before"}
              >
                <CubePanel
                  style={{ top: "0" }}
                  variants={{
                    drop: {
                      opacity: 1,
                      transition: {
                        when: "beforeChildren",
                        staggerChildren: 0.2,
                      },
                    },
                  }}
                  initial="before"
                  transform3d="rotateX(54deg) rotateZ(-45deg)"
                >
                  <CubeSticker
                    initial={{ z: 200, opacity: 0 }}
                    variants={{
                      before: { z: 200, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    bgColor="marine"
                  >
                    <StickerLabel color="snow">{Content.finance}</StickerLabel>
                  </CubeSticker>
                  <CubeSticker
                    initial={{ z: 400, opacity: 0 }}
                    variants={{
                      before: { z: 400, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    bgColor="silverfox"
                  ></CubeSticker>
                  <CubeSticker
                    initial={{ z: 500, opacity: 0 }}
                    variants={{
                      before: { z: 500, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    bgColor="marine"
                  >
                    <StickerLabel color="snow">{Content.and}</StickerLabel>
                  </CubeSticker>
                  <CubeSticker
                    initial={{ z: 300, opacity: 0 }}
                    variants={{
                      before: { z: 300, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    bgColor="marine"
                  >
                    <StickerLabel color="snow">
                      {Content.technology}
                    </StickerLabel>
                  </CubeSticker>
                </CubePanel>
                <CubePanel
                  variants={{
                    drop: {
                      transition: {
                        when: "beforeChildren",
                        staggerChildren: 0.3,
                      },
                    },
                  }}
                  style={{ top: "155px", left: "-27px" }}
                  transform3d="rotateX(-54deg) rotateZ(135deg) rotateY(-90deg) rotate(-90deg)"
                >
                  <CubeSticker
                    initial={{ opacity: 0, z: -100 }}
                    variants={{
                      before: { z: -100, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    bgColor="snow"
                  >
                    <StickerLabel>{Content.consumer}</StickerLabel>
                  </CubeSticker>
                  <CubeSticker
                    initial={{ opacity: 0, z: -300 }}
                    variants={{
                      before: { z: -300, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    bgColor="vermilion"
                  ></CubeSticker>
                  <CubeSticker
                    initial={{ opacity: 0, z: -200 }}
                    variants={{
                      before: { z: -200, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    bgColor="vermilion"
                  >
                    <StickerLabel color="silverfox">{Content.and}</StickerLabel>
                  </CubeSticker>
                  <CubeSticker
                    initial={{ opacity: 0, z: -400 }}
                    variants={{
                      before: { z: -400, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    bgColor="silverfox"
                  >
                    <StickerLabel>{Content.lifestyle}</StickerLabel>
                  </CubeSticker>
                </CubePanel>
                <CubePanel
                  variants={{
                    drop: {
                      transition: {
                        when: "beforeChildren",
                        staggerChildren: 0.3,
                      },
                    },
                  }}
                  style={{ top: "155px", left: "155px" }}
                  transform3d="rotateX(54deg) rotateZ(-45deg) rotateX(-90deg)"
                >
                  <CubeSticker
                    initial={{ opacity: 0, z: 400 }}
                    variants={{
                      before: { z: 400, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    patternColor="rainyday"
                    patternDirection="55deg"
                    bgColor="casino"
                  ></CubeSticker>
                  <CubeSticker
                    initial={{ opacity: 0, z: 200 }}
                    variants={{
                      before: { z: 200, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    patternColor="rainyday"
                    patternDirection="55deg"
                    bgColor="casino"
                  ></CubeSticker>
                  <CubeSticker
                    initial={{ opacity: 0, z: 100 }}
                    variants={{
                      before: { z: 100, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    patternColor="rainyday"
                    patternDirection="55deg"
                    bgColor="rainyday"
                  ></CubeSticker>
                  <CubeSticker
                    initial={{ opacity: 0, z: 300 }}
                    variants={{
                      before: { z: 300, opacity: 0 },
                      drop: {
                        opacity: 1,
                        z: 0,
                      },
                    }}
                    patternColor="rainyday"
                    patternDirection="55deg"
                    bgColor="casino"
                  ></CubeSticker>
                </CubePanel>
              </Cube>
            </>
          );
        }}
      </ScrollVisibilityObserver>
    </Header>
  );
};

export default Hero;
