import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import PageWrapper from "../components/layouts/Page";
import {
  Article,
  ArticleColumn,
  FixedWidth,
  Main,
  Section,
} from "../components/layout/Semantic";
import Hero from "../components/pages/about/Hero";
import { H2, H4, List, P } from "../components/copy";
import Content from "../content/about.json";
import {
  LeftTopTriangle,
  RightTopTriangle,
} from "../components/layout/Decorators";
import usePageWidth from "../hooks/usePageWidth";
import { CubeSticker } from "../components/pages/about/Hero";
import ScrollVisibilityObserver from "../components/utils/ScrollVisibilityObserver";

const LeftTopTriMod = styled(LeftTopTriangle)`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.COLORS.vermilion},
    ${({ theme }) => theme.COLORS.marine} 50%,
    ${({ theme }) => theme.COLORS.marine} 70%,
    ${({ theme }) => theme.COLORS.abyss} 97%
  );
  position: absolute;
  top: 0;
  left: 0;
`;

const RightTopTriMod = styled(RightTopTriangle)`
  background: repeating-linear-gradient(
    135deg,
    ${({ theme }) => theme.COLORS.rainyday} 0,
    ${({ theme }) => theme.COLORS.rainyday} 1px,
    transparent 1px,
    transparent 8px
  );
  position: absolute;
  top: 0;
  right: 0;
`;

const RightTopTriMode2 = styled(RightTopTriangle)`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.COLORS.abyss},
    ${({ theme }) => theme.COLORS.marine} 3%,
    ${({ theme }) => theme.COLORS.marine} 30%,
    ${({ theme }) => theme.COLORS.vermilion}
  );
  position: absolute;
  top: 0;
  right: 0;
  height: 196px;
  width: 75%;
`;

const ArticleWithColumns = styled(Article)`
  display: flex;
  flex-direction: column;

  ${ArticleColumn} {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  @media screen and (min-width: 960px) {
    flex-direction: row;
    flex-wrap: nowrap;

    ${ArticleColumn} {
      flex-grow: 0;
    }
  }
`;

const CubeWrapper = styled(motion.div)`
  width: 128px;
  height: 128px;
`;

const SingleSticker = ({
  isInView,
  transform3d = "rotateX(54deg) rotateZ(-45deg) rotateX(-90deg)",
  zAnimateDistance = -400,
  x = 0,
  y = 0,
  ...props
}) => {
  return (
    <CubeWrapper layout className="relative" style={{ x, y }}>
      <CubeWrapper
        className="absolute"
        style={{
          transform: transform3d,
          transformStyle: "preserve-3d",
        }}
      >
        <CubeSticker
          {...props}
          className="absolute"
          initial={{ z: zAnimateDistance, opacity: 0 }}
          variants={{
            hide: {
              opacity: 0,
              z: zAnimateDistance,
            },
            show: {
              opacity: 1,
              z: 0,
            },
          }}
        />
      </CubeWrapper>
    </CubeWrapper>
  );
};

const ArticleWithColumnsMod = styled(ArticleWithColumns)`
  margin: 0 0 50px;
`;

const ArticleColumnMod = styled(ArticleColumn)``;

// markup
const WhoWeArePage = (props) => {
  const { isMobile } = usePageWidth();

  return (
    <PageWrapper {...props} name="about">
      <Hero />
      <Main>
        <Section bgColor="snow" style={{ padding: "0 20px" }}>
          <FixedWidth>
            <H2 style={{ marginTop: "56px", marginBottom: "64px" }}>
              {Content.section_1.title}
            </H2>
            <ArticleWithColumnsMod>
              <ArticleColumnMod
                style={{
                  flexBasis: isMobile ? "100%" : "60%",
                  order: isMobile ? 2 : 1,
                }}
              >
                <P>{Content.section_1.part_1}</P>
              </ArticleColumnMod>
              <ScrollVisibilityObserver thresholds={[1]}>
                {({ isInView }) => {
                  return (
                    <ArticleColumnMod
                      className="relative"
                      style={{ margin: "40px 0", order: isMobile ? 1 : 2 }}
                      variants={{
                        hide: {
                          opacity: 0,
                        },
                        show: {
                          opacity: 1,
                          transition: {
                            when: "beforeChildren",
                            staggerChildren: 0.2,
                          },
                        },
                      }}
                      animate={isInView ? "show" : "hide"}
                    >
                      <SingleSticker
                        x={80}
                        y={-50}
                        transform3d="rotateX(54deg) rotateZ(-45deg) rotateX(-90deg)"
                        isInView={isInView}
                        patternColor="rainyday"
                        patternDirection="55deg"
                        bgColor="casino"
                        zAnimateDistance={400}
                      />
                    </ArticleColumnMod>
                  );
                }}
              </ScrollVisibilityObserver>
            </ArticleWithColumnsMod>
            <ArticleWithColumnsMod>
              <ScrollVisibilityObserver>
                {({ isInView }) => {
                  return (
                    <ArticleColumnMod
                      className="relative"
                      style={{
                        flexBasis: isMobile ? "100%" : "40%",
                        marginBottom: isMobile ? -50 : 0,
                      }}
                      initial={{ opacity: 0 }}
                      variants={{
                        hide: {
                          opacity: 0,
                        },
                        show: {
                          opacity: 1,
                          transition: {
                            when: "beforeChildren",
                            staggerChildren: 0.2,
                          },
                        },
                      }}
                      animate={isInView ? "show" : "hide"}
                    >
                      <SingleSticker
                        isInView={isInView}
                        bgColor="casino"
                        transform3d="rotateX(54deg) rotateZ(-45deg) rotateX(-90deg)"
                        x={20}
                        y={-20}
                        zAnimateDistance={400}
                      />
                      <SingleSticker
                        isInView={isInView}
                        patternColor="rainyday"
                        patternDirection="55deg"
                        bgColor="casino"
                        transform3d="rotateX(54deg) rotateZ(-45deg) rotateX(-90deg)"
                        x={150}
                        y={-80}
                        zAnimateDistance={400}
                      />
                      <SingleSticker
                        isInView={isInView}
                        bgColor="rainyday"
                        transform3d="rotateX(54deg) rotateZ(-45deg) rotateX(-90deg)"
                        x={isMobile ? 270 : 400}
                        y={-100}
                        zAnimateDistance={400}
                      />
                    </ArticleColumnMod>
                  );
                }}
              </ScrollVisibilityObserver>
              <ArticleColumnMod
                style={{ flexBasis: isMobile ? "100%" : "60%" }}
              >
                <P>{Content.section_1.part_2}</P>
              </ArticleColumnMod>
            </ArticleWithColumnsMod>
          </FixedWidth>
        </Section>
        <FixedWidth style={{ position: "relative" }}>
          <LeftTopTriMod style={{ width: "60%", height: "180px" }} />
          <RightTopTriMod style={{ width: "80%", height: "290px" }} />
        </FixedWidth>
        <Section style={{ padding: "0 20px" }}>
          <FixedWidth style={{ paddingTop: "200px" }}>
            <H2 style={{ marginTop: "56px", marginBottom: "64px" }}>
              {Content.section_2.title}
            </H2>
            <ArticleWithColumnsMod>
              <ArticleColumnMod
                style={{
                  flexBasis: isMobile ? "100%" : "60%",
                  order: isMobile ? 2 : 1,
                }}
              >
                <P>{Content.section_2.part_1}</P>
              </ArticleColumnMod>
              <ScrollVisibilityObserver>
                {({ isInView }) => {
                  return (
                    <ArticleColumnMod
                      style={{ order: isMobile ? 1 : 2 }}
                      className="relative"
                      initial={{ opacity: 0 }}
                      variants={{
                        hide: {
                          opacity: 0,
                        },
                        show: {
                          opacity: 1,
                          transition: {
                            when: "beforeChildren",
                            staggerChildren: 0.2,
                          },
                        },
                      }}
                      animate={isInView ? "show" : "hide"}
                    >
                      {" "}
                      <SingleSticker
                        transform3d="rotateX(54deg) rotateZ(-45deg)"
                        bgColor="silverfox"
                        x={20}
                        zAnimateDistance={400}
                      />
                      <SingleSticker
                        transform3d="rotateX(54deg) rotateZ(-45deg)"
                        bgColor="marine"
                        x={200}
                        zAnimateDistance={400}
                      />
                    </ArticleColumnMod>
                  );
                }}
              </ScrollVisibilityObserver>
            </ArticleWithColumnsMod>
            <ArticleWithColumnsMod>
              <ScrollVisibilityObserver>
                {({ isInView }) => {
                  return (
                    <ArticleColumnMod
                      style={{ flexBasis: isMobile ? "100%" : "40%" }}
                      className="relative"
                      initial={{ opacity: 0 }}
                      variants={{
                        hide: {
                          opacity: 0,
                        },
                        show: {
                          opacity: 1,
                          transition: {
                            when: "beforeChildren",
                            staggerChildren: 0.2,
                          },
                        },
                      }}
                      animate={isInView ? "show" : "hide"}
                    >
                      <SingleSticker
                        transform3d="rotateX(54deg) rotateZ(-45deg)"
                        bgColor="marine"
                        x={80}
                        y={-30}
                        zAnimateDistance={400}
                      />
                    </ArticleColumnMod>
                  );
                }}
              </ScrollVisibilityObserver>
              <ArticleColumnMod
                style={{ flexBasis: isMobile ? "100%" : "60%" }}
              >
                <H4>{Content.list_header}</H4>
                <List
                  listColor="marine"
                  items={Content.section_2.list_1.items}
                />
              </ArticleColumnMod>
            </ArticleWithColumnsMod>
          </FixedWidth>
        </Section>
        <FixedWidth className="relative">
          <RightTopTriMode2 />
        </FixedWidth>
        <Section bgColor="silverfox" style={{ padding: "50px 20px 0" }}>
          <FixedWidth>
            <H2 style={{ marginTop: "56px", marginBottom: "64px" }}>
              {Content.section_3.title}
            </H2>
            <ArticleWithColumnsMod>
              <ArticleColumnMod
                style={{
                  flexBasis: isMobile ? "100%" : "60%",
                  order: isMobile ? 2 : 1,
                }}
              >
                <P>{Content.section_3.part_1}</P>
              </ArticleColumnMod>
              <ScrollVisibilityObserver>
                {({ isInView }) => {
                  return (
                    <ArticleColumnMod
                      style={{ order: isMobile ? 1 : 2 }}
                      className="relative"
                      initial={{ opacity: 0 }}
                      variants={{
                        hide: {
                          opacity: 0,
                        },
                        show: {
                          opacity: 1,
                          transition: {
                            when: "beforeChildren",
                            staggerChildren: 0.2,
                          },
                        },
                      }}
                      animate={isInView ? "show" : "hide"}
                    >
                      {" "}
                      <SingleSticker
                        transform3d="rotateX(-54deg) rotateZ(135deg) rotateY(-90deg) rotate(-90deg)"
                        bgColor="vermilion"
                        x={20}
                      />
                      <SingleSticker
                        transform3d="rotateX(-54deg) rotateZ(135deg) rotateY(-90deg) rotate(-90deg)"
                        bgColor="snow"
                        x={200}
                      />
                    </ArticleColumnMod>
                  );
                }}
              </ScrollVisibilityObserver>
            </ArticleWithColumnsMod>
            <ArticleWithColumnsMod>
              <ScrollVisibilityObserver>
                {({ isInView }) => {
                  return (
                    <ArticleColumnMod
                      style={{ flexBasis: isMobile ? "100%" : "40%" }}
                      className="relative"
                      initial={{ opacity: 0 }}
                      variants={{
                        hide: {
                          opacity: 0,
                        },
                        show: {
                          opacity: 1,
                          transition: {
                            when: "beforeChildren",
                            staggerChildren: 0.2,
                          },
                        },
                      }}
                      animate={isInView ? "show" : "hide"}
                    >
                      <SingleSticker
                        transform3d="rotateX(-54deg) rotateZ(135deg) rotateY(-90deg) rotate(-90deg)"
                        bgColor="vermilion"
                        x={80}
                        y={-30}
                      />
                    </ArticleColumnMod>
                  );
                }}
              </ScrollVisibilityObserver>
              <ArticleColumnMod
                style={{ flexBasis: isMobile ? "100%" : "60%" }}
              >
                <H4>{Content.list_header}</H4>
                <List
                  listColor="vermilion"
                  items={Content.section_3.list_1.items}
                />
              </ArticleColumnMod>
            </ArticleWithColumnsMod>
          </FixedWidth>
        </Section>
      </Main>
    </PageWrapper>
  );
};

export default WhoWeArePage;
